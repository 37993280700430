class TableUtils {
  /**
   * Takes the DomNode table object passed by html-react-parser and extracts
   * the relevant header html, text, and attributes to recreate the table header
   * in React
   *
   * @param tableDomNode: DomNode - object passed from html-react-parser replace
   * function
   */
  static extractTableHeadData(tableDomNode) {
    const theadEl = tableDomNode?.children?.find(
      child => child.name === "thead"
    )
    const theadTrEl = theadEl?.children?.find(child => child.name === "tr")
    const theadTrThEls = theadTrEl?.children
    const tableHeadData = theadTrThEls?.map(th => {
      const { html, text } = TableUtils.extractHtmlAndTextFromThTd(th)
      return {
        html: html,
        text: text,
        attribs: TableUtils.replaceClassWithClassname(th?.attribs),
      }
    })
    return tableHeadData
  }

  /**
   * Takes the DomNode table object passed by html-react-parser and extracts
   * the relevant body html, text, and attributes to recreate the table body in
   * React
   *
   * @param tableDomNode: DomNode - object passed from html-react-parser replace
   * function
   */
  static extractTableBodyData(tableDomNode, tableHeadData) {
    const tbodyEl = tableDomNode?.children?.find(
      child => child.name === "tbody"
    )
    const tbodyTrEls = tbodyEl?.children
    const tableBodyData = tbodyTrEls?.map(tr => {
      let dataRow = {}
      tr?.children?.map((td, index) => {
        const dataKey = !!tableHeadData?.length
          ? tableHeadData[index].text
          : index
        const { html, text } = TableUtils.extractHtmlAndTextFromThTd(td)
        dataRow = {
          ...dataRow,
          [dataKey]: {
            html: html,
            text: text,
            attribs: TableUtils.replaceClassWithClassname(td?.attribs),
          },
        }
      })
      return dataRow
    })
    return tableBodyData
  }

  /**
   * Recursive function that takes a DomNode object starting with a th or td
   * element and recreates the html markup and text the node and its children
   * represent
   *
   * The html and text fields are built in the same function so that we only
   * loop through all th/td nodes (and their children) once
   */
  static extractHtmlAndTextFromThTd(domNode) {
    let html = ""
    let text = ""

    // loop through all child elements to build html and text fields
    domNode?.children?.forEach(childElement => {
      // exit condition
      if (childElement.type === "text") {
        html += childElement.data
        text += childElement.data
      }
      // dig another node deeper
      else if (childElement.type === "tag") {
        // build opening tag
        html += `<${childElement.name} ${
          childElement.name === "a" ? `href="${childElement.attribs.href}"` : ""
        }>`
        if (childElement.name !== "br") {
          const childData = TableUtils.extractHtmlAndTextFromThTd(childElement)
          text += childData.text // add content to text field
          html += childData.html // add content to html field
          html += `</${childElement.name}>` // build closing tag for html field
        }
      }
    })
    return { html: html, text: text }
  }

  // replaces the "class" key with "className" so it follows React convention
  static replaceClassWithClassname(attribs) {
    let updatedAttribs = {}
    if (!attribs) return updatedAttribs
    Object.keys(attribs).map(key => {
      if (key === "class") {
        updatedAttribs = {
          ["className"]: attribs[key],
          ...updatedAttribs,
        }
      } else {
        updatedAttribs = { [key]: attribs[key], ...updatedAttribs }
      }
    })
    return updatedAttribs
  }
}

export default TableUtils
