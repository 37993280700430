/**
 * Open CTA block video URLs in a modal
 */
export default function videoLinkModal() {
  if (typeof window !== "undefined" && window.document) {
    const links = document.querySelectorAll(
      "a[href*='vimeo'], a[href*='youtube']"
    )

    const videos = document.querySelectorAll(
      ".FeaturedImageBlock-image .Image.Video"
    )

    if (videos.length > 0) {
      ;[...videos].forEach(v => {
        v.addEventListener("click", e => {
          v.classList.add("video-loaded")
          const videoURL = v.dataset.url
          v.innerHTML = `<iframe src=${videoURL} width="640" height="360" allow="autoplay; fullscreen; picture-in-picture" frameborder="0" allowfullscreen></iframe>`
        })
      })
    }

    if (links.length < 1) return

    const lightboxModalOverlay = document.createElement("div")
    lightboxModalOverlay.classList = "Lightbox v--modal-overlay"
    lightboxModalOverlay.style.display = "none"
    lightboxModalOverlay.innerHTML = `<div class="v--modal-background-click"><div class="v--modal-top-right"></div> <div role="dialog" aria-modal="true" class="v--modal-box v--modal" style="width: 960px; height: 540px;"><button class="Lightbox-close"></button> <div class="Lightbox-content"><iframe width="100%" height="100%" allowfullscreen frameborder="0"></iframe></div></div></div>`
    document.body.append(lightboxModalOverlay)

    const closers = lightboxModalOverlay.querySelectorAll(
      ".Lightbox-close, .v--modal-background-click"
    )

    ;[...closers].forEach(c => {
      c.addEventListener("click", e => {
        e.preventDefault()
        const iframe = lightboxModalOverlay.querySelector("iframe")
        iframe.setAttribute("src", "")
        lightboxModalOverlay.style.display = "none"
      })
    })

    const youtube =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    const vimeoRegularUrl = /https?\:\/\/vimeo\.com\/([0-9]+)\/?/
    const vimeoPlayerUrl = /https?\:\/\/player\.vimeo\.com\/video\/([0-9]+)\/?/

    for (const link of links) {
      link.addEventListener("click", e => {
        e.preventDefault()

        let videoId = ""
        let match = null
        let provider = ""
        if (link.href.match(youtube)) {
          match = link.href.match(youtube)
          videoId = match[2]
          provider = "youtube"
        }
        if (link.href.match(vimeoRegularUrl)) {
          match = link.href.match(vimeoRegularUrl)
          videoId = match[1]
          provider = "vimeo"
        }
        if (link.href.match(vimeoPlayerUrl)) {
          match = link.href.match(vimeoPlayerUrl)
          videoId = match[1]
          provider = "vimeo"
        }
        if (!match) return

        const iframe = lightboxModalOverlay.querySelector("iframe")
        const src =
          provider === "youtube"
            ? `https://www.youtube.com/embed/${videoId}?rel=0&amp;showinfo=0&amp;autoplay=1`
            : `https://player.vimeo.com/video/${videoId}?autoplay=1&autopause=0`

        iframe.setAttribute("src", src)

        lightboxModalOverlay.style.display = "block"
      })
    }
  }
}
