import React, { useState } from "react"
import * as styles from "./Table.module.css"
import TableUtils from "./TableUtils"

const Table = ({ tableDomNode }) => {
  const tableHeadData = TableUtils.extractTableHeadData(tableDomNode)
  const tableBodyData = TableUtils.extractTableBodyData(
    tableDomNode,
    tableHeadData
  )

  // filter vars & functions
  const [filter, setFilter] = useState("")
  const handleSearchInputChange = e => {
    setFilter(e.target.value)
  }
  const filteredBodyRows = tableBodyData.filter(row => {
    return Object.keys(row).some(key =>
      row[key]?.text?.toLowerCase().includes(filter.toLowerCase())
    )
  })

  return (
    <>
      <div className={styles.SearchWrapper}>
        <input
          type="text"
          aria-placeholder="Search"
          placeholder="Search"
          value={filter}
          onChange={e => handleSearchInputChange(e)}
          className={styles.SearchInput}
        ></input>
        <button
          type="submit"
          title="Search"
          aria-label="Search"
          role="presentation"
          className={styles.SearchIcon}
        >
          <span>Search</span>
        </button>
      </div>

      {/* Table */}
      <table>
        <thead>
          <tr>
            {tableHeadData &&
              tableHeadData.map((headerObj, index) => {
                return (
                  <th
                    {...headerObj.attribs}
                    key={`${headerObj.html}-${index}`}
                    dangerouslySetInnerHTML={{ __html: headerObj.html }}
                  ></th>
                )
              })}
          </tr>
        </thead>
        <tbody>
          {filteredBodyRows &&
            filteredBodyRows.map((row, index) => {
              return (
                <tr key={`${Object.keys(row).join}-${index}`}>
                  {Object.keys(row).map((key, index) => {
                    return (
                      <td
                        {...row[key].attribs}
                        key={`${key}-${index}`}
                        dangerouslySetInnerHTML={{ __html: row[key].html }}
                      ></td>
                    )
                  })}
                </tr>
              )
            })}
        </tbody>
      </table>
    </>
  )
}

export default Table
